




































































































































































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import DataTableV2 from '@/app/ui/components/DataTableV2/index.vue'
import AscendingIcon from '@/app/ui/assets/ascending_icon.vue'
import Loading from '@/app/ui/components/Loading/index.vue'
import { Utils } from '@/app/infrastructures/misc'
import controller from '@/app/ui/controllers/PickupController'
import dayjs from 'dayjs'
import dayjsDuration from 'dayjs/plugin/duration'
import {
  Pickup,
  PickupCRRQUEs,
  PickupCRRQUESummary,
} from '@/domain/entities/Pickup'
import PaginationNav from '@/app/ui/components/PaginationNav/index.vue'
import {
  IOptions,
  IHeaderCell,
  ITableCell,
  Parameters,
} from '@/data/infrastructures/misc/interfaces/pickupCRRQUE'
import {
  SortFieldsCRRQUE,
  SortTypeCRRQUE,
} from '@/app/infrastructures/misc/Constants/pickup'
import DropdownSelect from '@/app/ui/components/DropdownSelect/index.vue'
import Button from '@/app/ui/components/Button/index.vue'
import IconSync from '@/app/ui/assets/icon_sync.vue'
import IconWhatsApp from '@/app/ui/assets/ics_f_whatsapp.vue'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import IconWarningCircle from '@/app/ui/assets/ics_f_warning_circle.vue'
import CourierCallCheckbox from '../components/CourierCallCheckbox/index.vue'
import { Pagination } from '@/domain/entities/Pagination'
import ModalForceCRRCNC from '../components/Modals/ModalForceCRRCNC.vue'
import ModalSuccess from '../components/Modals/ModalSuccess.vue'
import DropdownMultiSelect from '../components/DropdownMultiSelect/index.vue'
import SummarySection from '../components/Summary/index.vue'

dayjs.extend(dayjsDuration)

@Component({
  components: {
    TextInput,
    DataTableV2,
    AscendingIcon,
    Loading,
    PaginationNav,
    DropdownSelect,
    Button,
    IconSync,
    IconWhatsApp,
    LoadingOverlay,
    IconWarningCircle,
    CourierCallCheckbox,
    ModalForceCRRCNC,
    ModalSuccess,
    DropdownMultiSelect,
    SummarySection,
  },
})
export default class PickupCRRQUEPage extends Vue {
  controller = controller

  enumSelectedSort = SortTypeCRRQUE
  enumSortFields = SortFieldsCRRQUE

  headers = [
    this.headerCellMapper('No.', '60px'),
    this.headerCellMapper('Order ID', '160px'),
    this.headerCellMapper('Nama Customer', '200px'),
    this.headerCellMapper('No. Handphone', '190px'),
    this.headerCellMapper('Status', '140px'),
    this.headerCellMapper('Alamat', '260px'),
    this.headerCellMapper('Nama POS', '200px'),
    this.headerCellMapper('Durasi Pick Up (CRRQUE)', '200px'),
    this.headerCellMapper('Waktu Request', '176px'),
    this.headerCellMapper('Atur', '183px'),
  ]

  selectedSort: SortTypeCRRQUE | null = null
  parameters: Parameters = {
    page: 1,
    perPage: 10,
    search: '',
    shipmentType: [],
    pickupDuration: {
      label: 'Semua',
      value: 'all',
    },
    posCall: {
      label: 'Semua',
      value: '',
    },
  }

  shipmentTypeOptions = [
    {
      label: 'AP (Favorite)',
      value: 'AP',
    },
    {
      label: 'AG (Reguler)',
      value: 'AG',
    },
    {
      label: 'ARA (COD Reguler)',
      value: 'ARA',
    },
  ]

  pickupDurationOptions = [
    {
      label: 'Semua',
      value: 'all',
    },
    {
      label: '0 - 30',
      value: '30',
    },
    {
      label: '31 - 60',
      value: '60',
    },
    {
      label: '61 - 90',
      value: '90',
    },
    {
      label: '91 - 120',
      value: '120',
    },
    {
      label: '121 - 150',
      value: '150',
    },
    {
      label: '151 - 180',
      value: '180',
    },
    {
      label: '181 - 210',
      value: '210',
    },
    {
      label: '210+',
      value: '210+',
    },
  ]

  posCallOptions = [
    {
      label: 'Semua',
      value: '',
    },
    {
      label: 'Sudah Hubungi POS',
      value: true,
    },
    {
      label: 'Belum Hubungi POS',
      value: false,
    },
  ]

  tableData: ITableCell[][] = []

  perPageOptions: IOptions[] = [
    { label: '10', value: 10 },
    { label: '50', value: 50 },
    { label: '100', value: 100 },
  ]

  summary: PickupCRRQUESummary = {
    thirty: 0,
    sixty: 0,
    ninety: 0,
    onetwenty: 0,
    onefifty: 0,
    oneeighty: 0,
    twoten: 0,
    moretwoten: 0,
  }

  pagination = new Pagination()

  showForceCRRCNCModal = false
  showForceADMCNCModal = false
  forceCRRCNCShipmentId = ''
  forceADMCNCShipmentId = ''
  showSuccessForceCRRCNCModal = false
  showSuccessForceADMCNCModal = false

  created(): void {
    this.fetchPickups(true, true)
  }

  get params(): Record<string, string | number | boolean> {
    return {
      page: this.parameters.page,
      perPage: this.parameters.perPage,
      keyword: this.parameters.search,
      dateFrom: this.dateFrom(),
      shipmentType: this.parameters.shipmentType?.length
        ? <string>(
            this.parameters.shipmentType
              ?.map(
                type =>
                  this.shipmentTypeOptions.find(option => option.label === type)
                    ?.value
              )
              .join(',')
          )
        : 'ALL',
      durationPickup: encodeURIComponent(
        <string>this.parameters.pickupDuration?.value
      ),
      isPosContacted: <string>this.parameters.posCall?.value,
      sortDirection: this.selectedSort ? this.selectedSort.split('-')[1] : '',
      column: this.selectedSort ? this.selectedSort.split('-')[0] : '',
      summary: false,
    }
  }

  get minDate(): Date {
    return new Date(new Date().setDate(new Date().getDate() - 7))
  }

  get isexportPickupListLoading(): boolean {
    return controller.isExportCRRQUELoading
  }

  get pickupData(): Pickup[] {
    return controller.pickupData
  }

  get statusId(): string {
    return <string>this.$route.meta.additional.statusId
  }

  get isLoading(): boolean {
    return controller.isLoading
  }

  get latestSync(): string {
    if (!controller.latestSync) {
      return ''
    }

    return dayjs(controller.latestSync).format('DD-MM-YY HH:mm')
  }

  public onFilterShipmentType(value: string[]): void {
    this.parameters.shipmentType = value
    this.fetchPickups(true)
  }

  public onFilterPickupDuration(value: { label: string; value: string }): void {
    if (!value) {
      this.parameters.pickupDuration = {
        label: 'Semua',
        value: 'all',
      }
    }
    this.fetchPickups(true)
  }

  public onFilterPOSCall(value: { label: string; value: string }): void {
    if (!value) {
      this.parameters.posCall = {
        label: 'Semua',
        value: '',
      }
    }
    this.fetchPickups(true)
  }

  public dateFrom(): string {
    return dayjs()
      .subtract(1, 'week')
      .format('YYYY-MM-DD')
  }

  private fetchPickups(reset: boolean, sync = false): void {
    if (reset) this.parameters.page = 1

    const payload = { ...this.params }

    payload.summary = sync

    if (!this.parameters.search) {
      delete payload.keyword
    }

    this.controller.getPickupCRRQUE(payload)
  }

  public fetchPickupsPerPage(perPage: number): void {
    this.parameters.perPage = perPage
    this.fetchPickups(true)
  }

  public onTableSort(sortType: SortFieldsCRRQUE): void {
    switch (sortType) {
      case SortFieldsCRRQUE.ORDER_ID:
        if (this.selectedSort === SortTypeCRRQUE.ORDER_ID_ASC) {
          this.selectedSort = SortTypeCRRQUE.ORDER_ID_DESC
        } else if (this.selectedSort === SortTypeCRRQUE.ORDER_ID_DESC) {
          this.selectedSort = null
        } else {
          this.selectedSort = SortTypeCRRQUE.ORDER_ID_ASC
        }
        break

      case SortFieldsCRRQUE.CUSTOMER_NAME:
        if (this.selectedSort === SortTypeCRRQUE.CUSTOMER_NAME_ASC) {
          this.selectedSort = SortTypeCRRQUE.CUSTOMER_NAME_DESC
        } else if (this.selectedSort === SortTypeCRRQUE.CUSTOMER_NAME_DESC) {
          this.selectedSort = null
        } else {
          this.selectedSort = SortTypeCRRQUE.CUSTOMER_NAME_ASC
        }
        break

      case SortFieldsCRRQUE.PARTNER_NAME:
        if (this.selectedSort === SortTypeCRRQUE.PARTNER_NAME_ASC) {
          this.selectedSort = SortTypeCRRQUE.PARTNER_NAME_DESC
        } else if (this.selectedSort === SortTypeCRRQUE.PARTNER_NAME_DESC) {
          this.selectedSort = null
        } else {
          this.selectedSort = SortTypeCRRQUE.PARTNER_NAME_ASC
        }
        break

      case SortFieldsCRRQUE.CREATED_AT:
        if (this.selectedSort === SortTypeCRRQUE.CREATED_AT_ASC) {
          this.selectedSort = SortTypeCRRQUE.CREATED_AT_DESC
        } else if (this.selectedSort === SortTypeCRRQUE.CREATED_AT_DESC) {
          this.selectedSort = null
        } else {
          this.selectedSort = SortTypeCRRQUE.CREATED_AT_ASC
        }
        break

      default:
        if (this.selectedSort === SortTypeCRRQUE.PICKUP_DURATION_CRRQUE_ASC) {
          this.selectedSort = SortTypeCRRQUE.PICKUP_DURATION_CRRQUE_DESC
        } else if (
          this.selectedSort === SortTypeCRRQUE.PICKUP_DURATION_CRRQUE_DESC
        ) {
          this.selectedSort = null
        } else {
          this.selectedSort = SortTypeCRRQUE.PICKUP_DURATION_CRRQUE_ASC
        }
        break
    }

    this.fetchPickups(true)
  }

  public onSearch = Utils.debounce((search: string): void => {
    if (search.length > 2 || search.length === 0) {
      this.fetchPickups(true)
    }
  }, 500)

  public onClickCheckBoxContactCourier = async (
    index: number
  ): Promise<void> => {
    if (this.controller.isLoading) return
    if (this.controller.pickupCRRQUEData.data?.length) {
      const status = <boolean>(
        this.controller.pickupCRRQUEData.data[index].isContacted
      )
      const shipmentId = <string>(
        this.controller.pickupCRRQUEData.data[index].shipmentId
      )

      await controller.updateIsContactedCRRQUE({
        shipmentId: shipmentId,
        isContacted: !status,
        index: index,
      })
    }
  }

  public onForceCRRCNC(shipmentId: string): void {
    this.forceCRRCNCShipmentId = shipmentId
    this.showForceCRRCNCModal = true
  }

  public onForceADMCNC(shipmentId: string): void {
    this.forceADMCNCShipmentId = shipmentId
    this.showForceADMCNCModal = true
  }

  public onCancelForceADMCNC(): void {
    this.showForceADMCNCModal = false
    this.forceADMCNCShipmentId = ''
  }

  public onCancelForceCRRCNC(): void {
    this.showForceCRRCNCModal = false
    this.forceCRRCNCShipmentId = ''
  }

  private headerCellMapper(
    title: string | number,
    colWidth: string
  ): IHeaderCell {
    return {
      title: title,
      customStyle: {
        minWidth: colWidth,
        maxWidth: colWidth,
      },
    }
  }

  private tableCellMapper(
    value:
      | string
      | number
      | Date
      | string[]
      | { [key: string]: string | number | boolean },
    colWidth: string
  ): ITableCell {
    return {
      value,
      customStyle: {
        maxWidth: colWidth,
        minWidth: colWidth,
      },
    }
  }

  public syncData(): void {
    this.fetchPickups(true, true)
  }

  private getMinutes(time: string): number {
    const minutes = (new Date().getTime() - new Date(time).getTime()) / 60000
    return Number(minutes.toFixed())
  }

  public decideForceCRRCNC(status: string): boolean {
    return (
      status.substr(0, 2) === 'AG' ||
      status.substr(0, 3) === 'GAG' ||
      status.substr(0, 3) === 'ARA' ||
      status.substr(0, 4) === 'GARA'
    )
  }

  public decideForceADMCNC(status: string): boolean {
    return status.substr(0, 2) === 'AP'
  }

  public onExport(): void {
    const payload = this.params

    delete payload.page
    delete payload.perPage
    delete payload.summary

    this.controller.exportCRRQUE(this.params)
  }

  @Watch('controller.pickupCRRQUEData', { deep: true })
  onPickupCRRQUEDataChanged(data: PickupCRRQUEs): void {
    if (data.data) {
      this.pagination = <Pagination>data.pagination
      this.summary = <PickupCRRQUESummary>data.summary
      this.tableData = <ITableCell[][]>data.data.map((data, index) => {
        return [
          this.tableCellMapper(
            index +
              1 +
              this.parameters.perPage * (this.parameters.page - 1) +
              '.',
            '60px'
          ),
          this.tableCellMapper(<string>data.shipmentId, '160px'),
          this.tableCellMapper(<string>data.customer?.name, '200px'),
          this.tableCellMapper(<string>data.customer?.phoneNumber, '190px'),
          this.tableCellMapper(<string>data.flag, '140px'),
          this.tableCellMapper(
            <string[]>[
              data.pickup?.latitude,
              data.pickup?.longitude,
              data.customer?.address,
            ],
            '260px'
          ),
          this.tableCellMapper(
            [
              `[${data.pos?.agentCode}] </br> ${data.pos?.name} </br> [${data.pos?.phone}]`,
              <string>data.pos?.phone,
              <string>data.shipmentId,
            ],
            '200px'
          ),
          this.tableCellMapper(
            {
              minutes: this.getMinutes(<string>data.crrqueAt),
              canContact: <boolean>data.canContact,
              isContacted: <boolean>data.isContacted,
              index: index,
            },
            '200px'
          ),
          this.tableCellMapper(
            [
              Utils.formatDateWithIDLocale(
                <string>data.createdAt,
                'DD MMM YYYY'
              ),
              Utils.formatTimeZone(
                Utils.formatDateWithIDLocale(<string>data.createdAt, 'HH:mm Z')
              ),
            ],
            '176px'
          ),
          this.tableCellMapper(<string>data.shipmentId, '183px'),
        ]
      })
    }
  }

  @Watch('controller.isSuccessForceCancelCRRQUE')
  onIsSuccessForceCancelCRRQUEChanged(data: boolean): void {
    if (data) {
      if (this.forceADMCNCShipmentId) {
        this.showSuccessForceADMCNCModal = true
        this.showForceADMCNCModal = false
        this.forceCRRCNCShipmentId = ''
      }

      if (this.forceCRRCNCShipmentId) {
        this.showSuccessForceCRRCNCModal = true
        this.showForceCRRCNCModal = false
        this.forceADMCNCShipmentId = ''
      }
    }

    this.fetchPickups(true)
    this.controller.setIsSuccessForceCancelCRRQUE(false)
  }

  @Watch('controller.isFailedForceCancelCRRQUE')
  onIsFailedForceCancelCRRQUE(data: boolean): void {
    if (data) {
      if (this.forceADMCNCShipmentId) {
        this.showForceADMCNCModal = true
      }

      if (this.forceCRRCNCShipmentId) {
        this.showForceCRRCNCModal = true
      }

      this.controller.setIsFailedForceCancelCRRQUE(false)
    }
  }
}
