export enum EnumPickupFlag {
  CRRQUE = 'CRRQUE',
  CRRNFD = 'CRRNFD',
  DOX = 'DOX',
  CRROTW = 'CRROTW',
  DOLOTW = 'DOLOTW',
  CRRPIC = 'CRRPIC',
  CRRDON = 'CRRDON',
}

export enum EnumStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE'
}

export enum EnumActions {
  CANCEL = 'CANCEL',
  SUBMIT = 'SUBMIT'
}

export enum EnumPickupDay {
  TOMORROW = 'tomorrow',
  TODAY = 'today'
}

export enum SortFields {
  COURIER_ID = 'courier_id',
  COURIER_NAME = 'courier_name',
  START_PICKUP = 'start_pickup',
  END_PICKUP = 'end_pickup',
}

export enum SortType {
  COURIER_ID_ASC = 'courier_id-ASC',
  COURIER_ID_DESC = 'courier_id-DESC',
  COURIER_NAME_ASC = 'courier_name-ASC',
  COURIER_NAME_DESC = 'courier_name-DESC',
  START_PICKUP_ASC = 'start_pickup-ASC',
  START_PICKUP_DESC = 'start_pickup-DESC',
  END_PICKUP_ASC = 'end_pickup-ASC',
  END_PICKUP_DESC = 'end_pickup-DESC',
}

export enum SortFieldsCRROTW {
  ORDER_ID = 'sg_shipment_id',
  CUSTOMER_NAME = 'sp_fullname',
  PHONE_NUMBER = 'sp_phone_number',
  COURIER_NAME = 'actor_name',
  PICKUP_DURATION_CRROTW = 'crrotw_at',
  LAST_UPDATED = 'h_created_at',
  PICKUP_DURATION_SHPCRT = 'created_at',
  PICKUP_DURATION_FIRST_CRRSRC = 'crrsrc_at',
}

export enum SortTypeCRROTW {
  ORDER_ID_ASC = 'sg_shipment_id-ASC',
  ORDER_ID_DESC = 'sg_shipment_id-DESC',
  CUSTOMER_NAME_ASC = 'sp_fullname-ASC',
  CUSTOMER_NAME_DESC = 'sp_fullname-DESC',
  PHONE_NUMBER_ASC = 'sp_phone_number-ASC',
  PHONE_NUMBER_DESC = 'sp_phone_number-DESC',
  COURIER_NAME_ASC = 'actor_name-ASC',
  COURIER_NAME_DESC = 'actor_name-DESC',
  PICKUP_DURATION_CRROTW_ASC = 'crrotw_at-ASC',
  PICKUP_DURATION_CRROTW_DESC = 'crrotw_at-DESC',
  LAST_UPDATED_ASC = 'h_created_at-ASC',
  LAST_UPDATED_DESC = 'h_created_at-DESC',
  PICKUP_DURATION_SHPCRT_ASC = 'created_at-ASC',
  PICKUP_DURATION_SHPCRT_DESC = 'created_at-DESC',
  PICKUP_DURATION_FIRST_CRRSRC_ASC = 'crrsrc_at-ASC',
  PICKUP_DURATION_FIRST_CRRSRC_DESC = 'crrsrc_at-DESC',
}

export enum SortFieldsCRRQUE {
  ORDER_ID = 'shipment_id',
  CUSTOMER_NAME = 'customer_name',
  PARTNER_NAME = 'pos_name',
  PICKUP_DURATION_CRRQUE = 'crrque_at',
  CREATED_AT = 'created_at',
}

export enum SortTypeCRRQUE {
  ORDER_ID_ASC = 'shipment_id-ASC',
  ORDER_ID_DESC = 'shipment_id-DESC',
  CUSTOMER_NAME_ASC = 'customer_name-ASC',
  CUSTOMER_NAME_DESC = 'customer_name-DESC',
  PARTNER_NAME_ASC = 'pos_name-ASC',
  PARTNER_NAME_DESC = 'pos_name-DESC',
  PICKUP_DURATION_CRRQUE_ASC = 'crrque_at-ASC',
  PICKUP_DURATION_CRRQUE_DESC = 'crrque_at-DESC',
  CREATED_AT_ASC = 'created_at-ASC',
  CREATED_AT_DESC = 'created_at-DESC',
}
