










































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import CaretDownIcon from '@/app/ui/assets/caret_down_icon.vue'
import CloseLine from '@/app/ui/assets/close_line.vue'
import CheckedIcon from '@/app/ui/assets/check_line.vue'
import ExpandIcon from '@/app/ui/assets/expand_icon.vue'
import CheckBox from '../CheckBox/index.vue'

interface IOptions {
  label: string
  value: string | number
}

@Component({
  name: 'DropdownMultiSelect',
  components: {
    CaretDownIcon,
    CloseLine,
    CheckedIcon,
    ExpandIcon,
    CheckBox,
  },
})
export default class NestedMultiselect extends Vue {
  @Prop({ type: String }) placeholder!: string
  @Prop({ type: Array, default: () => [] }) value!: string[]
  @Prop({ type: String, default: 'Search' }) searchPlaceholder!: string
  @Prop({ type: Array, default: () => [] }) options!: IOptions[]
  @Prop({ type: Boolean, default: false }) withSearch!: boolean

  isDropdownVisible = false
  keyword = ''
  tempValue: Array<string> = []

  created(): void {
    this.tempValue = this.value
  }

  get filteredOptions(): Array<IOptions> {
    return this.onFilterOptions(this.options, this.keyword)
  }

  get isSelectedAll(): boolean {
    return this.options.filter((option) => !this.value.includes(option.label)).length === 0
  }

  private onSelectOption(value: string): void {
    if (this.isSelectedAll) return
    if (this.tempValue.includes(value)) {
      this.tempValue = this.tempValue.filter(item => item !== value)
    } else {
      this.tempValue.push(value)
    }
    this.$emit('input', this.tempValue)
  }

  private onSelectAll(): void {
    if (this.isSelectedAll) {
      this.$emit('input', [])
    } else {
      this.$emit('input', this.options.map((option) => option.label))
    }
  }

  private onRemoveItem(value: string): void {
    this.tempValue = this.tempValue.filter(item => item !== value)
    this.$emit('input', this.tempValue)
  }

  private isChecked(value: string): boolean {
    return this.tempValue.includes(value)
  }

  private onFilterOptions(
    list: Array<IOptions>,
    keyword: string
  ): Array<IOptions> {
    return list.filter(item =>
      (<string>item.value).toLowerCase().startsWith(keyword.toLowerCase())
    )
  }

  private onClickField(): void {
    this.isDropdownVisible = !this.isDropdownVisible
  }

  private onClickOutside(): void {
    this.keyword = ''
    this.isDropdownVisible = false
  }

  @Watch('value')
  private onChangeValue(value: string[]): void {
    this.tempValue = value
  }
}
