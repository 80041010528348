























































import { PickupCRROTWSummary } from '@/domain/entities/Pickup'
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({})
export default class Summary extends Vue {
  @Prop({ type: Object, required: false }) public summary!: PickupCRROTWSummary
}
