














































import { Component, Prop, Vue } from 'vue-property-decorator'
import Modal from '@/app/ui/components/Modal/index.vue'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import Button from '@/app/ui/components/Button/index.vue'
import controller from '@/app/ui/controllers/PickupController'

@Component({
  components: {
    Modal,
    TextInput,
    Button,
  },
})
export default class ModalForceCRRCNC extends Vue {
  @Prop({ type: Boolean, default: '' }) readonly visible!: string
  @Prop({ type: String, default: '', required: true }) shipmentId!: string
  @Prop({ type: String, default: 'CRRCNC' }) label!: string
  @Prop({ type: String, default: 'CRROTW'}) status!: string

  controller = controller

  reason = ''

  get disableButton(): boolean {
    return this.reason.length < 10
  }

  public onCancel(): void {
    this.reason = ''
    this.$emit('cancel')
  }

  public onSubmit(): void {
    this.$emit('submit')

    if (this.status === 'CRROTW') {
      this.controller.forceCRRCNC({
        reason: this.reason,
        shipmentId: this.shipmentId,
      })
    }

    if (this.status === 'CRRQUE') {
      this.controller.forceCancelCRRQUE({
        note: this.reason,
        shipmentId: this.shipmentId,
        status: this.label
      })
    }
  }
}
